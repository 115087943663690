window.CookieConsent.init({
    //increase version with each change
    cookieVersion: 1,
    // More link URL on bar
    modalMainTextMoreLink: '/stranka/cookies',
    // How lond to wait until bar comes up
    barTimeout: 1000,
    //show cross button at the bottom
    showCloseButton: false,
    //three button layout
    useThreeButtonBar: true,
    //consent expiration in days, if not set, it will have no expiration
    consentExpiration: 393, //approx 13 months
    // Look and feel
    theme: {
        barColor: '#c6a171',
        barTextColor: '#202414',
        barMainButtonColor: '#21347b',
        barMainButtonTextColor: '#ffffff',
        modalMainButtonColor: '#21347b',
        modalMainButtonTextColor: '#ffffff',
    },
    language: {
        current: 'sk',
        locale: {
            sk: {
                barMainText: 'Tieto webové stránky využívajú cookies na ukladanie dát potrebných pre zlepšovanie kvality prehliadania. Jednotlivé cookies môžete prijať alebo odmietnuť na základe nastavení, ktoré nájdete v sekcii Nastavenia cookies. Kým cookies v tejto sekcii nenastavíte, nekliknete na tlačidlo „Akceptovať“ alebo ich odmietnete, nebudú sa ukladať, okrem cookies potrebných pre technický chod stránky.',
                barLinkSetting: 'Nastavenie cookies',
                barBtnAcceptAll: 'Akceptovať všetky cookies',
                barBtnReject: 'Odmietnuť všetky',
                modalMainTitle: 'O súboroch cookies',
                modalMainTextMore: ' Informácie o používaní cookies.',
                modalMainText: 'Cookies sú malé textové súbory, ktoré môžu byť použité webovými stránkami, aby zefektívnili užívateľovu skúsenosť. Zákon hovorí, že môžeme ukladať súbory cookie na vašom zariadení, ak sú nevyhnutné pre prevádzku týchto stránok. Pri všetkých ostatných typoch súborov cookie potrebujeme váš súhlas. Táto stránka používa rôzne typy cookies. Niektoré cookies sú tu umiestnené službami tretích strán, ktoré sa objavujú na našich stránkach.',
                modalBtnSave: 'Uložiť nastavenia',
                modalBtnAcceptAll: 'Akceptovať všetky cookies a zavrieť',
                modalAffectedSolutions: 'Dotknuté cookies:',
                learnMore: 'Viac informácií',
                on: 'Zapnuté',
                off: 'Vypnuté',
                cookieName: 'Názov',
                cookiePurpose: 'Popis / účel',
                cookieExpiration: 'Expirácia',
                cookieType: 'Typ',
                closeButtonText: 'Zavrieť',
                toggleSectionText: 'Rozbaliť / zbaliť sekciu'
            }
        }
    },

    categories: {
        necessary: {
            needed: true,
            wanted: true,
            checked: true,
            language: {
                locale: {
                    sk: {
                        name: 'Potrebné',
                        description: 'Potrebné súbory cookie pomáhajú vytvárať použiteľné webové stránky tak, že umožňujú základné funkcie, ako je navigácia stránky a prístup k chráneným oblastiam webových stránok. Webové stránky nemôžu riadne fungovať bez týchto súborov cookies.',
                    }
                }
            }
        },
        statistic: {
            needed: false,
            wanted: false,
            checked: false,
            language: {
                locale: {
                    sk: {
                        name: 'Štatistiky',
                        description: 'Štatistické súbory cookies pomáhajú majiteľom webových stránok, aby pochopili, ako komunikovať s návštevníkmi webových stránok prostredníctvom zberu a hlásenia informácií anonymne.',
                    }
                }
            }
        }
    },
    services: {
        system: {
            category: 'necessary',
            language: {
                locale: {
                    sk: {
                        name: 'Systémové (ASP.NET_SessionId, __unam)'
                    }
                }
            }
        },

        cookieconsent: {
            category: 'necessary',
            language: {
                locale: {
                    sk: {
                        name: 'Cookie Consent (cconsent) - knižnica na správu povolených cookies'
                    }
                }
            }
        },

        googleanalytics: {
            category: 'statistic',
            type: 'wrapped', // dynamic-script, script-tag, wrapped, localcookie
            search: 'googleanalytics',
            language: {
                locale: {
                    sk: {
                        name: 'Google analytics (_ga,_gat_newTracker, _gid, _gat_rollup, _rollupGa, _rollupGa_gid ) - cookies používané analytickým nástrojom pre analýzu návštevnosti podstránok a pohybu užívateľov'
                    }
                }
            }
        },
    }
});